/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select'
import Popup from 'reactjs-popup';

import CommonLink from 'components/CommonLink/CommonLink'
import Input from 'components/Input/Input'
import CheckBox from 'components/CheckBox/CheckBox'
import TextArea from 'components/Input/TextArea'
import CompanyRating from 'components/CompanyRating/CompanyRating';
import CompaniesSearchInput from 'components/CompaniesSearchInput/CompaniesSearchInput';
import RadiosList from 'components/RadiosList/RadiosList';
import SuccessRequestPopup from 'components/SuccessRequestPopup/SuccessRequestPopup';
import PreloaderS from 'assets/images/PreloaderS';

import { knowThisEmployer, haveBeenEmployed, yesNoList, regardlessOfEmployees, believeAgeBias } from 'constants/reviewFormData'
import { generateRange, scrollToTop } from 'services/helpers'
import { selectStyles } from './selectStyles'
import { setFeedbackModal, setSelectedCompany } from 'redux/reducers/reviewsReducer';
import { useAddReviewMutation } from 'services/api/reviewsApi';
import { emailValidate, messageValidate } from '../validations';

import './EmployerReviewForm.scss'
import 'react-phone-input-2/lib/material.css';

const EmployerReviewForm = ({ refetch = () => null }) => {
  const [createReview, { isLoading }] = useAddReviewMutation()
  const company = useSelector(state => state.reviewsReducer.selectedCompany)
  const ratingErrorRef = useRef(null)
  const messageErrorRef = useRef(null)
  const companyErrorRef = useRef(null)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const lastWorkedYears = generateRange(2000, 2023)
  const bornYears = generateRange(1936, 1985)
  const [ratingError, setRatingError] = useState('')
  const [companyError, setCompanyError] = useState('')
  const [messageError, setMessageError] = useState('')
  const [mailErrors, setMailErrors] = useState('')
  const [loading, setLoading] = useState(false)
  const [requestSuccess, setRequestSuccess] = useState(false)
  const [requestAnswer, setRequestAnswer] = useState({
    title:'',
    body:''
  })
  const [formData, setFormData] = useState({
    rating: 0,
    explanation_of_rating: "",
    know_employer_because: "",
    time_employed: "",
    last_worked: "",
    was_born_in: "",
    believe_in_bias: "",
    bias_change: "",
    personal_experience: "",
    satisfaction_without_bias: "", 
    worked_location_city: "",
    worked_location_state: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    anonymous: false,
    subscribe_to_email: true
  })
  const [companyData, setCompanyData] = useState({
    company_id: company.id,
    company_name: company.name,
    company_loc: "",
  })

  useEffect(() => {
    scrollToTop()
  },[])

  const handleChange = (target) => {
    const { name, value } = target;
    if(name === 'believe_in_bias' || name === 'personal_experience') {
      setFormData((prevData) => ({
        ...prevData,
        [name]: Number(value),
      }))
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }))
    }
    if(name==='email'){
      setMessageError(messageValidate(value))
    }
  }

  const validateForm = (formData, companyData) => {
    if(!companyData.company_name) {
      setCompanyError("The company name is a required field.")
      scrollToTop()
    } else if (!formData.rating) {
      setRatingError("This field can't be empty. Please rate a company")
      scrollToTop()
    
    } else if(!formData.email) {
      setMailErrors("This fiels is required.")
    } else {
      setRatingError('')
      setCompanyError('')
      setMessageError('')
      setMailErrors('')
      return true
    }
    if (!companyData.company_name) {
      companyErrorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    if (!formData.rating) {
      ratingErrorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    
  }

  const handleCleanData = () => {
    setFormData({
      rating: 0,
      explanation_of_rating: "",
      know_employer_because: "",
      time_employed: "",
      last_worked: "",
      was_born_in: "",
      believe_in_bias: "",
      bias_change: "",
      personal_experience: "",
      satisfaction_without_bias: "", 
      worked_location_city: "",
      worked_location_state: "",
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      anonymous: false,
      subscribe_to_email: false
    })

    setCompanyData({
      company_id: company.id,
      company_name: company.name,
      company_loc: "",
    })
    dispatch(setSelectedCompany({name: '', id: ''}))
  }

  const handleCloseForm = () => {
    if(location.pathname === '/reviews/new') {
      navigate('/reviews')
    }
  }

  async function handleSubmit(e) {
    e.preventDefault()
    if(validateForm(formData, companyData)) {
      setLoading(true)
      try {
        await createReview({company: companyData, review: formData}).unwrap()
        setLoading(isLoading)
        refetch()
        handleCleanData()
        setRequestSuccess(true)
        setRequestAnswer({
          title: 'Request sent',
          body: 'Thank you for submitting your request.'
        })
        scrollToTop()
        setTimeout(() => {
          setRequestSuccess(false)
          handleCleanData()
          handleCloseForm()
          dispatch(setFeedbackModal(false))
        }, 2000)
      } catch(error) {
        console.log(error)
        setLoading(false)
        setRequestSuccess(true)
        setRequestAnswer({
          title: '',
          body: 'Something went wrong, please try again.'
        })
      }
    }
  }

  return (
    <div className='employerReviewForm'>
        <Popup position='center center' open={requestSuccess}>
          <SuccessRequestPopup
            onClose={() => {
              setRequestSuccess(false) 
              handleCloseForm()
            }}
            title={requestAnswer.title}
            description={requestAnswer.body}
            buttonText='Ok'
          />
        </Popup>
        <div className='employerReviewForm_header'>
            <p className='employerReviewForm_header__text'>The purpose of this anonymous survey is for you to rate an employer's workplace environment and company values as to whether they are (i) generally age friendly, (ii) unwelcoming to workers age 50+, or (iii) in between. We're committed to protecting our community from fraudulent submissions that might affect our ratings and reviews. We review and approve every post before it appears on our site: <CommonLink text={'View ratings'} path={'/reviews'}/>. </p>
            <p className='employerReviewForm_header__text'>Your submission and the employer's response, if any, is included in our confidential evaluation of this employer's current or future application to be designated as a: <CommonLink text={'Certified Age Friendly Employer'} blank={true} path={'https://institute.agefriendly.com/initiatives/certified-age-friendly-employer-program/'}/>.</p>
            <form className='employerReviewForm_form' id='employerReviewForm' onSubmit={handleSubmit}>
              <div className='employerReviewForm_form__inputGroup' ref={companyErrorRef}>
                <p>1. Please enter the name of the employer you are rating:</p>
                {company.name 
                ? <Input
                    label={'Company name'}
                    value={companyData.company_name}
                    placeholder={companyData.company_name}
                    disabled={true}
                    required={true}
                  />
                : <CompaniesSearchInput 
                    setCompanyData={setCompanyData}
                    label='Company name'
                    companyData={companyData}
                    error={companyError}
                    setError={setCompanyError}
                  />
                }

              </div>

              <div className='employerReviewForm_form__inputGroup company_rating'>
                <p>2. How Age Friendly is this employer?</p>
                <div className='rate_container' ref={ratingErrorRef}>
                  <span>1 star = not age friendly; 5 stars = very age friendly</span>
                  <CompanyRating handler={handleChange} error={ratingError} setError={setRatingError}/> 
                </div>
              </div>

              <div className='employerReviewForm_form__inputGroup' ref={messageErrorRef}>
                <p>3. My explanation for my rating in the previous question is as follows:</p>
                <TextArea
                  name='explanation_of_rating'
                  placeholder="Type message text here"
                  onChange={handleChange}
                  error={messageError}
                  messageText={messageError}
                />
              </div>

              <div className='employerReviewForm_form__inputGroup'>
                <p>4. I know this employer because:</p>
                <RadiosList data={knowThisEmployer} name={'know_employer_because'} handler={handleChange}/>
              </div>

              <div className='employerReviewForm_form__inputGroup'>
                 <p>5. I have been, or was, employed by this employer for:</p>
                <RadiosList data={haveBeenEmployed} name={'time_employed'} handler={handleChange}/>
              </div>

              <div className='employerReviewForm_form__inputGroup'>
                <p>6. last worked for this employer in:</p>
                <Select
                  options={lastWorkedYears}
                  styles={selectStyles}
                  placeholder={'Please select'}
                  onChange={e => handleChange({name: 'last_worked', value: e.value})}
                />
              </div>

              <div className='employerReviewForm_form__inputGroup'>
                <p>7. I was born in:</p>
                <Select
                  options={bornYears}
                  styles={selectStyles}
                  placeholder={'Please select'}
                  onChange={e => handleChange({name: 'was_born_in', value: e.value})}
                />
              </div>

              <div className='employerReviewForm_form__inputGroup'>
                <p>8. I believe age bias is a fact of life in the workplace:</p>
                <RadiosList data={yesNoList} name={'believe_in_bias'} handler={handleChange}/>
              </div>

              <div className='employerReviewForm_form__inputGroup'>
                <p>9. I believe age bias in the workplace is:</p>
                <RadiosList data={believeAgeBias} name={'bias_change'} handler={handleChange}/>
              </div>

              <div className='employerReviewForm_form__inputGroup'>
                <p>10. I have personally experienced or observed workplace age bias:</p>
                <RadiosList data={yesNoList} name={'personal_experience'} handler={handleChange}/>
              </div>

              <div className='employerReviewForm_form__inputGroup'>
                <p>11. Regardless of its treatment of employees based on age (good or bad), I rate my overall satisfaction with this employer as:</p>
                <RadiosList data={regardlessOfEmployees} name={'satisfaction_without_bias'} handler={handleChange}/>
              </div>

              <div className='employerReviewForm_form__inputGroup'>
                <p>12. I worked for this employer primarily in the following location:</p>
                <div className='inputGroup_group'>
                  <Input 
                    label='City'
                    type='text'
                    name='worked_location_city'
                    required={false}
                    onChange={handleChange}
                  />
                  <Input 
                    label='State'
                    type='text'
                    name='worked_location_state'
                    required={false}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className='employerReviewForm_form__inputGroup'>
                <p>13. This survey is anonymous. But if you are open to being contacted by RetirementJobs.com's Research Group, please provide your contact information. Your name or contact information will never be shared with the employer.</p>
                <div className='fieldsGroup'>
                    <Input
                      label="First Name"
                      addClass="commonFormInput first_name"
                      required={false}
                      onChange={handleChange}
                      name="first_name"
                      value={formData.first_name}
                    />

                    <Input
                      label="Last Name"
                      addClass="commonFormInput last_name"
                      required={false}
                      onChange={handleChange}
                      name="last_name"
                      value={formData.last_name}
                    />

                    <Input
                      type="email"
                      label="Email Address"
                      addClass="commonFormInput email"
                      required={true}
                      onChange={handleChange}
                      name="email"
                      value={formData.email}
                      messageText={mailErrors}
                      error={!!mailErrors}
                    />

                  {/* <div className="input-group commonFormInput phone">
                    <label>
                      Phone number<span>*</span>{" "}
                    </label>
                    <PhoneInput
                      inputProps={{
                        name: "phone",
                        required: true,
                      }}
                      excludeCountries={["ru", "by"]}
                      preferredCountries={["us"]}
                      country={"us"}
                      value={formData.phone}
                      onChange={e => handleChange({name:'phone', value: e})}
                      containerClass={"react-tel-input"}
                      inputClass={`custom-phone-input ${
                        phoneErrors && "error"
                      }`}
                      buttonClass={"custom-phone-button"}
                      dropdownClass={"custom-dropdown"}
                    />
                    {phoneErrors && <p className="error">{phoneErrors}</p>}
                  </div> */}
                </div>
                <CheckBox
                  label='Keep this comment anonymous'
                  name='anonymous'
                  onChange={e => handleChange({name: 'anonymous', value: e.target.checked})}
                  checked={formData.anonymous}
                />

                <CheckBox
                  label='Subscribe me to newsletters and job alerts from RetirementJobs.com'
                  name='subscribe_to_email'
                  onChange={e => handleChange({name: 'subscribe_to_email', value: e.target.checked})}
                  checked={formData.subscribe_to_email}
                />

              </div>
              <div className='button_wrapper'>
                <button className='commonButton careerServices' type='submit'>Submit</button>
                {loading && <PreloaderS margin='auto 20px'/>}
              </div>

            </form>
        </div>
    </div>
  )
}

export default EmployerReviewForm