import React, { useEffect, useState, useRef } from 'react';
import { Typeahead, Menu } from 'react-bootstrap-typeahead';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import searchIcon from 'assets/images/search-icon.svg';
import { useGetReviewsSearchResultsQuery } from 'services/api/reviewsApi';
import { generateURL } from 'services/helpers';
import { setNameFilter } from 'redux/reducers/filterReducer';

import './ReviewsSearch.scss';
import 'react-bootstrap-typeahead/css/Typeahead.css';

const ReviewsSearch = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { letterFilter, ratingFilter } = useSelector((state) => state.filterReducer);
  const [options, setOptions] = useState([]);
  const [company, setCompany] = useState('');
  const [checkResults, setCheckResults] = useState();
  const [showDropdown, setShowDropdown] = useState(false);
  const typeaheadRef = useRef(null);
  const inputRef = useRef()
  const { data } = useGetReviewsSearchResultsQuery({ company });

  useEffect(() => {
    setOptions(data?.companies.map(option => ({ label: option.name, value: option.id })));
  }, [company, data]);

  useEffect(() => {
    inputRef.current.clear()
  }, [letterFilter, ratingFilter])

  const renderMenu = results => (
    <Menu
      id='custom-menu'
      className='custom-menu-class'
      emptyLabel='We do not have this employer in our database'
    >
      {results.map((result, index) => (
        <Link
          key={index}
          className='custom-menu-item'
          to={`/reviews/companies/${generateURL(result.label)}`}
          onClick={() => setShowDropdown(false)}
        >
          <span className='searchLink'>{result.label}</span>
        </Link>
      ))}
    </Menu>
  );

  const handleOutsideClick = event => {
    if (typeaheadRef.current && !typeaheadRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  const handleSearch = () => {
    dispatch(setNameFilter(checkResults ? company : ''))
    navigate('/reviews/companies')
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div className='reviewsSearch'>
      <label htmlFor='reviewsSearchInput'>
        <img src={searchIcon} alt='search-icon' onClick={() => handleSearch()}/>
      </label>

      <div ref={typeaheadRef}>
        <Typeahead
          id='reviewsSearchInput'
          multiple={false}
          placeholder={'Type company name'}
          ref={inputRef}
          renderMenu={results => {
            setCheckResults(results.length)
            return renderMenu(results) 
          }}
          onChange={selected => {
            setCompany(selected);
            setShowDropdown(false);
          }}
          onInputChange={e => {
            setCompany(e);
            setShowDropdown(true);
          }}
          options={options}
          filterBy={() => true}
          open={showDropdown}
          onKeyDown={e => {
            if(e.key === 'Enter' && checkResults) {
              handleSearch()
            }
        }}
        />
      </div>
    </div>
  );
};

export default ReviewsSearch;
