import React from 'react';
import { Typeahead, Menu, MenuItem } from 'react-bootstrap-typeahead';
import './AutocompleteInput.scss';
import 'react-bootstrap-typeahead/css/Typeahead.css';

const AutocompleteInput = ({ 
    onChange, 
    setSelectedOption,
    selectedOption,
    options,
    addClass,
    label
}) => {

  const handleInputChange = (selected) => {
    setSelectedOption(selected);
  };

  const renderMenu = (results) => {
    return (
      <Menu 
        id='custom-menu'
        className="custom-menu-class"
      >
        {results.map((result, index) => (
          <MenuItem key={index} option={result} className='custom-menu-item'>
            {result.label}
          </MenuItem>
        ))}
      </Menu>
    );
  };

  return (
    <div className='autocompleteInput'>
        <label htmlFor='autocomplete-input'>{label}</label>
        <Typeahead
            id="autocomplete-input"
            labelKey="label"
            multiple={false}
            options={options?.length ? options?.map((option) => ({label: option.value, zip: option.data.zip})) : []}
            inputProps={{
              pattern: "[0-9]*"
            }}
            placeholder="Enter City, State or ZIP"
            onChange={handleInputChange}
            onInputChange={e => onChange(e.length > 1 ? e : '')}
            selected={selectedOption}
            menuClassName="custom-menu-class"
            renderMenu={results => renderMenu(results)}
            filterBy={() => true}
        />
        {addClass && <span className={addClass}></span>}  
    </div>
  );
};

export default AutocompleteInput;
