/* eslint-disable */
import React, { Fragment, useEffect } from 'react'
import './CompaniesAlphabeticalFilter.scss'
import { alphabet } from 'constants/textContent'
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setLetterData, setNameFilter } from 'redux/reducers/filterReducer';

const SymbolItem = ({ symbol }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const location = useLocation()
    const { letterFilter } = useSelector((state) => state.filterReducer);

    useEffect(() => {
      if(location.pathname === '/reviews'){
        dispatch(setLetterData(''))
      }
    }, [location])
    
    const handleClick = (e) => {
      const value = e.target.getAttribute('data-value')
      dispatch(setLetterData(value))
      dispatch(setNameFilter(''))
      navigate('/reviews/companies'); 
    }

    return (
      <div className='symbolItem'>
        <span className={`symbol ${letterFilter === symbol ? 'active' : ''}`} data-value={symbol} onClick={handleClick}>{symbol}</span> 
      </div>
    )
}

const CompaniesAlphabeticalFilter = ({ mobile = false }) => {
  const renderSymbols = alphabet.map(item => <Fragment key={item}><SymbolItem symbol={item}/></Fragment>)
  return (
    <div className='companiesAlphabeticalFilter'>
      {!mobile && <h4>A - Z <span>0 - 9</span></h4>}
      {!mobile && <p>Browse Reviews of Employers</p>}
      <div className='companiesAlphabeticalFilter_letters'>
        {renderSymbols}
      </div>
    </div>
  )
}

export default CompaniesAlphabeticalFilter